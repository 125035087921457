import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class NoCacheInterceptor implements HttpInterceptor {
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
            request = request.clone({
                setHeaders: { 
                    'Cache-Control': 'no-cache',
                    'Pragma': 'no-cache',
                    'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT'        
                }
            });
        return next.handle(request);
    }
}