import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { NgModule } from '@angular/core';
import { RouterModule, PreloadAllModules } from '@angular/router';
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { AppComponent } from './components/structure/app/app.component';
import { MainPageComponent } from './components/structure/main-page/main.page.component';
import { JwtInterceptor } from './interceptors/jwt.interceptor';
import { ErrorInterceptor } from './interceptors/error.interceptor';
import { ThrobberInterceptor } from './interceptors/throbber.interceptor';
import { MainBarComponent } from './components/structure/main-page/main-bar/main.bar.component';
import { TilesComponent } from './components/structure/main-page/tiles/tiles.component';
import { NavBarComponent } from './components/structure/nav-bar/nav.bar.component';
import { NoCacheInterceptor } from './interceptors/noCache.interceptor';

@NgModule({
  declarations: [
    AppComponent,    
    NavBarComponent,
    MainPageComponent,
    MainBarComponent,
    TilesComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule.forRoot([
      { path: '', component: MainPageComponent, pathMatch: 'full' },
      { path: '', loadChildren: "./modules/lazyLodedApp.module#LazyLoadedAppModule"}
          ],
          {preloadingStrategy: PreloadAllModules})
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ThrobberInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: NoCacheInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
