import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { CrudService } from './crud.service';


@Injectable({
    providedIn: 'root',
})
export class UsersService extends CrudService<any> {
    constructor(client: HttpClient) {
        super(client);
    }
    apiControllerUrl="api/users";

    get currentUser() {
        var currentUserJson = sessionStorage.getItem('currentUser');
        return currentUserJson ? JSON.parse(currentUserJson) : null;
    }
};
