import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthService } from '../services/auth.service'

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private authenticationService: AuthService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            if (err.status === 401 || err.status === 403) {
                // auto logout if 401/403 response returned from api
                this.authenticationService.logout();
                location.reload(true);
            }
            else if(err.status==400){
                alert(err.error.errorMessage);
            }
            else if(err.status!=406){//406 dla walidacji login form tymczasowo(?)
                alert("Wystąpił niespodziewany wyjątek. Spróbuj ponownie. Jeżeli problem będzie się powtarzał, skontaktuj się z administratorem strony.")
            }
            return throwError(err);
        }))
    }
}