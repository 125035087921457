import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { UsersService } from 'src/app/services/users.service';
import { RolesService } from 'src/app/services/roles.service';

declare var $: any;
@Component({
  selector: 'dk-nav-bar',
  templateUrl: './nav.bar.component.html',
  styleUrls: ['./nav.bar.component.css']
})
export class NavBarComponent implements OnInit {

  constructor(private location: Location, private userService: UsersService, private rolesService: RolesService) {
  }

  ngOnInit() {
    let that = this;
    $(window).scroll(function () {
      if (that.menuFixed() && $(document).scrollTop() > 40) {
        $('nav').addClass('shrink');
      } else {
        $('nav').removeClass('shrink');
      }
    });
  }

  get showLogout() {
    return this.userService.currentUser != null;
  }

  get isAdmin() {
    let that = this;
    return this.userService.currentUser != null && this.userService.currentUser.roles.filter(function (item) { return item == that.rolesService.admin; }).length > 0;
  }

  menuFixed() {
    let isFixed = this.location.path() != "";
    if (isFixed) {
      $('body').css('padding-top', '75px');
    }
    else {
      $('body').css('padding-top', '0px');
    }

    return isFixed;
  }

  navClicked(e) {
    $('#navbarSupportedContent').collapse('hide');
  }
}
