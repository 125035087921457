import { Injectable } from "@angular/core";

@Injectable({ providedIn: 'root' })
export class ThrobberService {
    private _throbberVisible: boolean;
    private throbberCounter = 0;
    get throbberVisible() { return this._throbberVisible };

    showThrobber() {
        var that=this;
        Promise.resolve(null).then(function () {
            that.throbberCounter++;
            that._throbberVisible = true;
        });
    }
    hideThrobber() {
        var that=this;
        Promise.resolve(null).then(function () {
            that.throbberCounter--;
            if (that.throbberCounter <= 0) {
                that.throbberCounter = 0;
                that._throbberVisible = false;
            }
        });
    }
}