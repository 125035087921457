import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router'
import { ThrobberService } from 'src/app/services/throbber.service';
import { trigger, style, state, transition, animate } from '@angular/animations';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  animations: [
    trigger('fadeIn', [
      state('invisible', style({
        opacity: 0
      })),
      state('visible', style({
        opacity: 1
      })),
      transition('invisible => visible', animate("0.3s")),
    ]),
  ]
})
export class AppComponent implements OnInit {
  isVisible = false;
  constructor(private router: Router, private throbberService: ThrobberService) {
  }

  get throbberHidden() {
    return !this.throbberService.throbberVisible;
  };

  ngOnInit() {
    setTimeout(()=>{
        this.isVisible=true;
    }, 100)
    this.router.events.subscribe((evt) => {      
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0)
    });
  }
}
