import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { ThrobberService } from '../services/throbber.service';

@Injectable()
export class ThrobberInterceptor implements HttpInterceptor {
    constructor(private throbberService: ThrobberService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.throbberService.showThrobber();
        return next.handle(request).pipe(finalize(()=>{
            this.throbberService.hideThrobber();
        }));
    }
}