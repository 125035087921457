import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { IBaseModel } from '../models/baseModel.interface';

export abstract class CrudService<T extends IBaseModel> {
    constructor(protected client: HttpClient) {
    }
    abstract apiControllerUrl:string;

    getList(): Observable<Array<T>> {
        return this.client.get(this.apiControllerUrl).pipe(map((result: any) => {
            return result;
        }));
    };

    save(model:T): Observable<any> {
        return this.client.post(this.apiControllerUrl, model).pipe((result:any)=>{
            return result;
        });
    }

    delete(model:T): Observable<any> {
        return this.client.delete(`${this.apiControllerUrl}/${model._id}`).pipe((result:any)=>{
            return result;
        });
    }
};
