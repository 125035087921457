import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';


@Injectable({
    providedIn: 'root',
})
export class AuthService{
constructor(private client:HttpClient){

}
    login(user: any):Observable<any>{
       return this.client.post("api/auth", user).pipe(map((result:any)=>{
            if (result && result.token) {
                sessionStorage.setItem('currentUser', JSON.stringify(result));
            }
            return user;
        }));
    }

    logout() {
        sessionStorage.removeItem("currentUser");
    }

};
