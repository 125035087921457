import { Component } from '@angular/core';

@Component({
  selector: 'dk-main-bar',
  templateUrl: './main.bar.component.html',
  styleUrls: ['./main.bar.component.css']
})
export class MainBarComponent {
 
}
