import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { CrudService } from './crud.service';


enum Roles{
    admin=1,
    user=2,
}

@Injectable({
    providedIn: 'root',
})

export class RolesService extends CrudService<any> {
    constructor(client: HttpClient) {
        super(client);
    }
    apiControllerUrl="api/roles";

    public get admin(){return Roles.admin};
    public get user(){return Roles.user};
};
