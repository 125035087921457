import { Component } from '@angular/core';

@Component({
  selector: 'dk-tiles',
  templateUrl: './tiles.component.html',
  styleUrls: ['./tiles.component.css']
})
export class TilesComponent {
 
}
